<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Niet toegestaan" />
    <h1>Bezoeken van pagina niet toegestaan</h1>
    <p>
      Je heeft geen bevoegdheid om de informatie op deze pagina in te zien,
      mogelijk moet je opnieuw
      <router-link :to="{ name: 'login' }">inloggen</router-link>.
    </p>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style></style>
